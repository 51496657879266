import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRefTop } from "../../../redux/actions/home.actions.js";
import { ASSET_URL } from "../../../constants.js"

import "./index.scss";

export const SectionTop = (props) => {
  const refTop = useRef(null);
  const dispatch = useDispatch();

  // parallax
  const [parallaxActive, setParallaxActive] = useState(false);

  // set random bubble
  const [randomBubble, setRandomBubble] = useState(0);

  const { refsMint } = useSelector((state) => state.home);


  useEffect(() => {
    dispatch(setRefTop(refTop));

    if (!randomBubble) {
      setRandomBubble(Math.floor(Math.random() * 3) + 1);
    }
  }, []);

  useEffect(() => {
    if (props.scrollPosition) {
      const scrollY = props.scrollPosition;
      const topStart = refTop.current.offsetTop;
      const topEnd = topStart + refTop.current.getBoundingClientRect().height;

      if (scrollY >= topStart && scrollY <= topEnd) {
        setParallaxActive(true);
      } else {
        setParallaxActive(false);
      }
    }
  });

  const handleScrollToMintSection = () => {
    if (refsMint) {
      window.scrollTo(0, refsMint.current.offsetTop)
    }
  }


  return (
    <>
      <div className="section section-top full-page" ref={refTop}>
        <div className="container">
          <div className="content">
            <div className="bin-kingz-content">
              <div className="left hidden-sm">
                <img
                  className="left-bubble"
                  src={`/images/speech-bubbles/animated/${randomBubble} CONVO.gif`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.35 : 0
                    }px) rotate(-5deg)`,
                  }}
                />
                <img
                  className="trash"
                  src={`${ASSET_URL}/landing/trash-left.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.80 : 0
                    }px)`,
                  }}
                />
                <img
                  className="bin-small"
                  src={`${ASSET_URL}/landing/bin-left.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.90 : 0
                    }px`,
                  }}
                />
                <img
                  className="bird"
                  src={`${ASSET_URL}/landing/bird-left.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.60 : 0
                    }px`,
                  }}
                />
                <img
                  className="bird-small"
                  src={`${ASSET_URL}/landing/bird-small-left.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.65 : 0
                    }px`,
                  }}
                />
                <img
                  className="puddle"
                  src={`${ASSET_URL}/landing/green.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.55 : 0
                    }px`,
                  }}
                />
              </div>
              <div className="center">
                <div className="title-header">
                  <img
                    src={`${ASSET_URL}/landing/BinKingz – The Flappening.png`}
                    alt="the flappening"
                  />
                </div>

                <div className="subtitle-header text-center">
                  THE BIN CHICKEN IS MIGRATING TO THE METAVERSE
                </div>

                <div
                  className="play-video-text text-center no-wrap cursor-pointer"
                >
                  Press here to 
                    <span onClick={() => handleScrollToMintSection()}>
                      &emsp;MINT&emsp;
                    </span> 
                  your bin chicken
                </div>
              </div>

              <div className="right hidden-sm">
                <img
                  className="right-bubble"
                  src={`/images/speech-bubbles/animated/${randomBubble} CONVO RESPONSE.gif`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.35 : 0
                  }px) rotate(4deg)`,
                    width: `${randomBubble === 2 ? 36 : 26}%`,
                  }}
                />
                <img
                  className="trash"
                  src={`${ASSET_URL}/landing/trash-right.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.80 : 0
                    }px`,
                  }}
                />
                <img
                  className="bin-small"
                  src={`${ASSET_URL}/landing/bin-right.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.90 : 0
                    }px`,
                  }}
                />
                <img
                  className="bird"
                  src={`${ASSET_URL}/landing/bird-right.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.60 : 0
                    }px`,
                  }}
                />
                <img
                  className="bird-small"
                  src={`${ASSET_URL}/landing/bird-small-right.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.65 : 0
                    }px`,
                  }}
                />
                <img
                  className="puddle"
                  src={`${ASSET_URL}/landing/blue.png`}
                  alt=""
                  style={{
                    transform: `translateY(${
                      parallaxActive ? props.scrollPosition * -0.55 : 0
                    }px`,
                  }}
                />
              </div>
            </div>
            <div className="bin-kingz-content small">
              <div className="left">
                <img
                  className="left-bubble"
                  src="/images/speech-bubbles/animated/A1 CONVO.gif"
                  alt=""
                />
                <img className="trash" src={`${ASSET_URL}landing/left.png`} alt="" />
                <img
                  className="puddle"
                  src={`${ASSET_URL}/landing/green.png`}
                  alt=""
                />
              </div>
              <div className="right">
                <img
                  className="right-bubble"
                  src="/images/speech-bubbles/animated/A2 CONVO RESPONSE.gif"
                  alt=""
                />
                <img className="trash" src={`${ASSET_URL}/landing/right.png`} alt="" />
                <img className="puddle" src={`${ASSET_URL}/landing/blue.png`} alt="" />
              </div>
            </div>

            
            <div className="bird-container-small">
              <div className="left-bird">
                <img 
                    className="bird-big" 
                    src={`images/landing/left-small.png`}
                    alt=""
                  />
                <img
                  className="bubble-small left"
                  src={`/images/speech-bubbles/animated/${randomBubble} CONVO.gif`}
                  alt=""
                />
                </div>
                <div className="right-bird">
                  <img 
                    className="bird-big" 
                    src={`images/landing/right-small.png`}
                    alt=""
                  />
                  <img
                    className="bubble-small right"
                    src={`/images/speech-bubbles/animated/${randomBubble} CONVO RESPONSE.gif`}
                    alt=""
                    style={{ width: randomBubble === 2 ? '38%' : '28%'}}
                  />
                </div>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};
