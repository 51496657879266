import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setRefFaq } from "../../../redux/actions/home.actions";
import { faqContent } from "../../../constants.js";

import { FaqItem } from "./FaqItem/index";

import { RANDOM_BUBBLE_FAQ } from "../../../constants.js";
import { ReactComponent as Oil } from "./images/yellow.svg";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./index.scss";

export const SectionFaq = (props) => {
  const dispatch = useDispatch();

  const ref = useRef(null);

  // car
  const carRef = useRef(null);
  const [carStartPosition, setCarStartPosition] = useState("top");
  const CAR_START_POSITION = 0.57;
  const CAR_END_POSITION = 0.18;

  // parallax
  const [parallaxActive, setParallaxActive] = useState(false);
  const [parallaxPercentage, setParallaxPercentage] = useState(0);
  const [randomBubble, setRandomBubble] = useState(null);

  // media query
  const DISABLE_CAR_ANIMATION = "(max-width: 860px)";
  const mediaQuery = window.matchMedia(DISABLE_CAR_ANIMATION);

  // set stuff
  useEffect(() => {
    dispatch(setRefFaq(ref));

    if (randomBubble === null) {
      const nBubbles = RANDOM_BUBBLE_FAQ.length;
      setRandomBubble(Math.floor(Math.random() * nBubbles));
    }
  }, [ref]);

  // scroll parallax
  useEffect(() => {
    if (props.scrollPosition) {
      const scrollY = props.scrollPosition;
      const topStart = ref.current.offsetTop;
      const topEnd = topStart + ref.current.getBoundingClientRect().height;

      if (scrollY >= topStart && scrollY <= topEnd) {
        setParallaxActive(true);
      } else {
        setParallaxActive(false);
      }

      // parallax for birds
      if (scrollY >= topStart && scrollY <= topEnd) {
        const percentage =
          (topEnd - scrollY) / ref.current.getBoundingClientRect().height;
        setParallaxPercentage((1 - percentage) * 100);
      }
    }
  }, [props.scrollPosition, ref]);

  const translateCar = () => {
    
    try {

      const offset = 400;
      const scrollY = window.scrollY;
      const sectionStart = ref.current.offsetTop;
      const sectionHeight = ref.current.getBoundingClientRect().height;
      const totalHeight = sectionStart + sectionHeight;

      // get the percentage scrolled (with an offset)
      const percentageScrolled = (totalHeight - scrollY) / sectionHeight - 0.50;
      const path = document.getElementById("yellow-oil");
      const svgLength = path.getTotalLength();
      const dist = svgLength * percentageScrolled;
      let point;

      // if not too small
      if (mediaQuery.matches) return

      // if we are within the boundaries
      if (scrollY >= sectionStart - offset && scrollY <= totalHeight) {
        point = path.getPointAtLength(dist);

        // regex the translate value from the svg
        const translateValue = /\(([^)]+)\)/
          .exec(path.attributes.transform.value)[1]
          .split(" ")
          .map((x) => Number(x));

        // we want it to move along a certain boundary only
        if (
          percentageScrolled < CAR_START_POSITION &&
          percentageScrolled > CAR_END_POSITION
        ) {
          if (!mediaQuery.matches) {
            // apply
            carRef.current.style.transform = `translate(${
              point.x + translateValue[0]
            }px,${point.y + translateValue[1]}px)`;
          }
        }
      }

      // otherwise consider which one to set
      if (scrollY < sectionStart) {
        point = path.getPointAtLength(CAR_START_POSITION);
        setCarStartPosition("top");
        setCarPosition(CAR_START_POSITION);
      }

      if (scrollY > totalHeight) {
        point = path.getPointAtLength(CAR_END_POSITION);
        setCarStartPosition("bottom");
        setCarPosition(CAR_END_POSITION);
      }
    } catch (err) {

    }
  };

  const setCarPosition = (percentage) => {

    const path = document.getElementById("yellow-oil");
    const svgLength = path.getTotalLength();
    const point = path.getPointAtLength(svgLength * percentage);
    const translateValue = /\(([^)]+)\)/
      .exec(path.attributes.transform.value)[1]
      .split(" ")
      .map((x) => Number(x));
    carRef.current.style.transform = `translate(${point.x + translateValue[0]}px,${point.y + translateValue[1]}px)`;
  };

  useEffect(() => {
    translateCar()
    window.addEventListener("scroll", () => translateCar());
    return () => {
      window.removeEventListener("scroll", () => translateCar());
    };
  }, [carStartPosition]);

  return (
    <>
      <div className="section section-faq bg-blue" ref={ref}>
        <div className="container full-page d-flex justify-content-center align-items-center">
          <div className="content">
            <div className="title-content-medium small">
              JOIN THE
              <br />
              REVOLTING
              <br />
              REVOLT
            </div>
            <div className="content-left">
              <div className="oil-container-small">
                <img src="/images/faq/bird-small.png" alt="bird-small" />
              </div>
              <div className="oil-container">
                <div className="bird">
                  <div className="bubble">
                    <img
                      src={`/images/speech-bubbles/animated/${RANDOM_BUBBLE_FAQ[randomBubble]}.gif`}
                      alt="honk"
                    />
                  </div>
                  <img className="bird-img" src="images/faq/faqs.png" alt="" />
                </div>
                <div id="car" className="car" ref={carRef}>
                  <img src="images/faq/WRX.png" alt="" />
                </div>
                <Oil />
              </div>
            </div>
            <div className="content-right">
              <div className="title-content-medium regular">
                JOIN THE
                <br />
                REVOLTING
                <br />
                REVOLT
              </div>
              <div className="faq-content">
                {faqContent.map((el, idx) => {
                  return (
                    <FaqItem
                      key={idx}
                      question={faqContent[idx].question}
                      answer={faqContent[idx].answer}
                      idx={idx}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
