import React, { useState, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux'

import { ASSET_URL } from '../../constants.js'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { setHeaderVisibility } from '../../redux/actions/home.actions.js'

import "./index.scss";

export const Loading = () => {
    const [loaded, setLoaded] = useState(false);

    const dotsRef = useRef(null);
    const dispatch = useDispatch();


    useEffect(() => {
        // disable scroll
        document.body.style.overflow = "hidden";
        
        // first set visibility ot none
        dispatch(setHeaderVisibility(false));

        let counter = 0
        let interval = setInterval(() => {
            const dots = dotsRef.current.innerHTML;
            if (dots.length < 3) {
                dotsRef.current.innerHTML += ".";
            } else {
                dotsRef.current.innerHTML = "";
            };
        }, 800);

        setTimeout(() => {
            clearInterval(interval)
            document.body.style.overflow = "initial";
            dispatch(setHeaderVisibility(true))
            setLoaded(true)
            ScrollTrigger.refresh();
        }, 5000)

        return () => {
            setLoaded(false)
            window.scrollTo(0, 0)
        }
    }, [dotsRef])
    return (
        <>
            <div className={`loading-background ${loaded ? 'hidden' : ''}`}>
                <div className="container">
                    <div className="loading-bin">
                        <img src={`${ASSET_URL}/landing/bird-right.png`} alt="" />
                    </div>
                    <div className="loading-text">
                        Loading<span className="dots" ref={dotsRef}></span>
                    </div>
                </div>
            </div>
        </>
    )
}