export const ASSET_URL =
  'https://binkingz-assets.s3.ap-southeast-2.amazonaws.com/web'

export const gridStyle = [
  { gridArea: '10 / 1 / 26 / 7' },
  { gridArea: '1 / 18 / 9 / 27' },
  { gridArea: '1 / 36 / 14 / 42' },
]

export const faqContent = [
  {
    question: 'What are Bin Kingz?',
    answer:
      '6,969 completely unique NFT bin chickens, each with their own unique traits, rarities and personalities. Holding one of these will give you exclusive access to events, wearables and giveaways.',
  },
  {
    question: 'Where do I purchase Bin Kingz?',
    answer: 'Bin Kingz can be purchased on our website: www.binkingz.com.',
  },
  {
    question: 'How much are we talking?',
    answer:
      'A Bin Kingz NFT will cost 0.069 ETH each. You must pay using Ethereum, the auction won’t accept credit cards or any other type of cryptocurrency.',
  },
  {
    question: 'What if I miss out?',
    answer:
      'Each Bin Kingz NFT is drawn from a pool of 6,969 unique artworks. Yep, they’re scarce. And there will never be a second drop, so once these sell out you’ll have to try to get them off the secondary market (Opensea Marketplace).',
  },
  {
    question: 'How do I resell my NFT?',
    answer:
      'Once you own the NFT you can auction or sell it within OpenSea (visit www.opensea.io). Connect your digital wallet, create an account and list your NFT for sale from your “Profile” section.',
  },
  {
    question: 'What is Opensea marketplace and how does it work?',
    answer:
      'OpenSea is a marketplace for digital goods including collectibles, gaming items, digital art, and other digital assets that are backed by a blockchain such as Ethereum. On OpenSea, you can buy, sell, and trade any of these items with anyone in the world.',
  },
  {
    question: 'What is an NFT anyway?',
    answer: (
      <>
        A Non-Fungible Token (NFT) is created for every digital item that’s
        published, making the digital creation (art, photograph, song...)
        finite.
        <br />
        <br />
        That's because it’s been digitally signed by the creator and uniquely
        identified on the blockchain. In a world where anything digital can be
        infinitely copied, a unique digital creation can only be owned by a
        single individual.
        <br />
        <br />
        Essentially, the NFT token is what provides proof of ownership and
        authenticity of your creation.
      </>
    ),
  },
  {
    question: 'What’s a digital wallet?',
    answer:
      'A digital wallet is software that manages your cryptocurrencies and also allows you to interact with blockchain technologies like Ethereum. Your wallet is your ID on the Ethereum blockchain and is used to digitally sign all of your creations. If you need to create a personal wallet, we recommend using MetaMask.',
  },
  {
    question: 'What is Ethereum?',
    answer: (
      <>
        Ethereum is a blockchain that enables a smart contract, the agreement
        between the buyer and the seller of an item.
        <br />
        <br />
        Ethereum is also considered by many as a world computer. That means that
        any information stored on it isn't managed by any central authority, but
        rather stored across thousands of computers worldwide.
      </>
    ),
  },
  {
    question: 'What is Ether?',
    answer:
      'Ether or Ξ is the cryptocurrency used to interact with Ethereum, and it’s what you’ll use to purchase Bin Kingz.',
  },
  {
    question: 'What is gas?',
    answer: (
      <>
        Ethereum is considered a world computer, operated by many computers
        across the world. This means that interacting with it incurs a
        transaction fee called gas to compensate the operators of Ethereum.
        <br />
        <br />
        This gas fee is paid in Ether and can vary in price depending on how
        quickly you wish to publish your creations and the demand on the
        blockchain. At this time, gas fees associated with creation, setting
        prices and sending artworks will need to be paid for by the artist or
        collector.
      </>
    ),
  },
  {
    question: 'Alright, I’m in. How do I purchase a Bin Kingz NFT?',
    answer: (
      <>
        You’ll need to set up a MetaMask Wallet and add some Ethereum (ETH) as
        the auction will not accept credit cards or any other type of
        cryptocurrency.
        <br />
        <br />
        Video instructions detailing how to set up a MetaMask account can be
        found
        <a
          href="https://www.youtube.com/watch?v=Af_lQ1zUnoM"
          target="_blank"
          rel="noreferrer"
        >
          {' '}
          HERE
        </a>
        <br />
        <br />
        Head to our website (www.binkingz.com), select the amount of NFTs you
        wish to purchase and press the MINT button. Follow the prompts to
        finalise your purchase.
        <br />
        <br />
        For further assistance, please use our Discord community for guidance or
        message us directly via Twitter @bin_kingz.
      </>
    ),
  },
]

export const RANDOM_BUBBLE_FAQ = [
  'HOWYAGOIN_',
  'NO GRONKS JUST HONKS',
  'PECK YA HEAD (eshay nike tns)',
  "WHERE'S THE BINS AT_",
  'WHO DRANK MY BIN JUICE_',
  'WHO YOU CALLIN A GOOSE_',
]

export const RANDOM_BUBBLE_LEFT = [
  'HOWYAGOIN_',
  'NO GRONKS JUST HONKS',
  'PECK YA HEAD (eshay nike tns)',
  "WHERE'S THE BINS AT_",
  'WHO DRANK MY BIN JUICE_',
  'WHO YOU CALLIN A GOOSE_',
]

export const RANDOM_BUBBLE_RIGHT = [
  'CRAP N FLAP',
  'FKN HONK!',
  'MINT ONE YA DOG',
  'PUB FEED_  (bogan, beer, mullet, ciggie)',
]

export const ROADMAP_GOALS = [
  {
    title: 'Hero The Bin Kingz Insignia: Stickers and Physical Art Giveaway',
    content: (
      <>
        Sticker packs for all holders to mark our territory around the world.
        Plus, the Ibis Original Artwork Giveaway Competition with six lucky
        winners receiving an original 1/1 Scott Marsh painting.
      </>
    ),
  },
  {
    title: 'Collect your Staunch Uniform: Baclava Wearables',
    content: (
      <>
        Time to don the official Bin Kingz attire, a collection of Scott Marsh
        balaclavas as metawear to show ’em who we are across the metaverse.
      </>
    ),
  },
  {
    title: 'Bin Kingz Teasure Hunt: Scavengers get ready for the ultimate hunt',
    content: (
      <>
        Scavengers will have to search for spray painted Bin Kingz in suburbs
        all across Australia. Be the first to snap a Chook & score a free Bin
        King.
      </>
    ),
  },
  {
    title: 'Rally the troops: Weekly Bin Kingz spaces for future planning',
    content: (
      <>
        Join our weekly Twitter Spaces to talk about the project, ask questions
        and give suggestions as we move toward victory.
      </>
    ),
  },
  {
    title: 'Funding the fight: Future Scott Marsh artworks and installations',
    content: (
      <>
        A portion of the proceeds will go back into funding materials for Scott
        Marsh to create bigger and better, completely uncensored artworks. Like
        large scale exhibitions and public sculptural works.
      </>
    ),
  },
]
