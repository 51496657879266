import {
  SET_ACCOUNT,
  SET_REF_ROADMAP,
  SET_REF_ABOUT,
  SET_REF_FAQ,
  SET_REF_TOP,
  SET_REF_MINT,
  SET_REF_VIDEO,
  SET_WEB3,
  SET_REF_TAKEOVER,
  SET_HEADER_VISIBILITY,
  SET_REF_BIN_JUICE,
} from "../constants/home.action.types";

const home = (state = {}, action) => {
  switch (action.type) {
    case SET_REF_ROADMAP:
      return {
        ...state,
        refsRoamap: action.payload,
      };

    case SET_REF_ABOUT:
      return {
        ...state,
        refsAbout: action.payload,
      };

    case SET_REF_FAQ:
      return {
        ...state,
        refsFaqs: action.payload,
      };
    
    case SET_REF_MINT:
      return {
        ...state,
        refsMint: action.payload,
      };
    
    case SET_REF_TOP:
      return {
        ...state,
        refsTop: action.payload,
      };

    case SET_REF_VIDEO:
      return {
        ...state,
        refsVideo: action.payload,
      }
    
    case SET_REF_TAKEOVER:
      return {
        ...state,
        refsTakeover: action.payload,
      }
    
    case SET_REF_BIN_JUICE:
      return {
        ...state,
        refsBinJuice: action.payload,
      }

    case SET_WEB3:
      return {
        ...state,
        web3: action.payload,
      };

    case SET_ACCOUNT:
      return {
        ...state,
        account: action && action.payload ? action.payload : null,
      };

    case SET_HEADER_VISIBILITY:
      return {
        ...state,
        headerVisibility: action.payload,
      }

    default:
      return state;
  }
};

export default home;
