import React, { useRef, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setRefBinJuice } from '../../../redux/actions/home.actions.js'
import { Link } from 'react-router-dom'

import './index.scss'

export const SectionBinJuice = (props) => {

  const refBinJuice = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRefBinJuice(refBinJuice))
  }, [])


  return (
    <>
      <div className='section section-bin-juice' ref={refBinJuice}>
        <div className="container full-page">
          <div className='bin-juice-container'>
            <div className='header-logo'>
              <img className='bin-juice-collab' src='./images/bin-juice/bk-yuli.png' alt=''/>
            </div>

            <div className='main-body container'>
              <div className='part left regular'>
                <img className='bird' src='./images/bin-juice/bin-juice-bird.png' alt=''/>
              </div>

              <div className='part center'>
                <div className='text-header font-main'>
                  Alright ya&nbsp;<br />cooked chooks.
                </div>
                <p className='text-description'>
                  We’ve partnered with Yulli’s Brews to bring you Bin Juice.
                  <br /><br />
                  Sink your beak into this fresh n’ feisty frothy befitting the filthiest flappers. A cloudy concoction of juicy passion fruit, a trio of the finest hops, and a soft, malty base of oats and chit malt. A beer balanced better than a one-legged pigeon on a power pole, brewed exclusively for the Bin Kingz community.
                  <br /><br />
                  Waddya still readin’ for? Grab yourself some Bin Juice and rip into it.
                </p>

                <div className='incentive-can'>
                  <div className='incentive-top'>
                    <img className='beer' src='./images/bin-juice/gold-can.png' alt='' />
                    <div className='text font-large'>
                      Find a golden tinny 
                      & win a free 
                      bin kingz nft
                    </div>
                    <img className='bird' src='./images/bin-juice/bird-1.png' alt='' />
                  </div>
                  <p className='incentive-info'>
                    Found 1 of 20 golden beer cans? It's ya lucky day mate.
                    <br />
                    Scan the unique QR code to claim your free Bin Kingz!
                  </p>
                </div>

                <div className='small-part'>
                  <img className='bird' src='./images/bin-juice/bin-juice-bird.png' alt=''/>
                  <div className='beer-cans'>
                    <img className='beer' src='./images/bin-juice/beer-cans.png' alt=''/>
                    <Link 
                      to={{
                        pathname: 'https://www.yullisbrews.com.au/product-page/bin-juice-hazy-ipa-16x375ml'
                      }}
                      target='_blank'
                      rel='noopener noreferrer'
                    >
                      <div className='cta'>
                        Get your bin 
                        <br />
                        juice here
                      </div>
                    </Link>
                  </div>
                </div>
              </div>

              <div className='part right regular'>
                <div className='beer-cans'>
                  <img className='beer' src='./images/bin-juice/beer-cans.png' alt=''/>
                  <Link 
                    to={{
                      pathname: 'https://www.yullisbrews.com.au/product-page/bin-juice-hazy-ipa-16x375ml'
                    }}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    <div className='cta'>
                      Get your bin 
                      <br />
                      juice here
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  )
}