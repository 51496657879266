import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { ASSET_URL } from "../../../constants.js";
import { setRefsVideo } from "../../../redux/actions/home.actions.js";

import "./index.scss";

export const SectionVideo = (props) => {
  const dispatch = useDispatch();
  const videoRef = useRef(null);

  const [parallaxActive, setParallaxActive] = useState(false);
  const [parallaxPercentage, setParallaxPercentage] = useState(0);

  // video overlay clicked?
  const [videoClicked, setVideoClicked] = useState(false);

  // disable parallax
  const DISABLE_PARALLAX = "(max-width: 992px)";
  const mediaQuery = window.matchMedia(DISABLE_PARALLAX);

  useEffect(() => {
    dispatch(setRefsVideo(videoRef));
  }, []);

  useEffect(() => {
    if (props.scrollPosition) {
      const scrollY = props.scrollPosition;
      const topStart = videoRef.current.offsetTop;
      const topEnd = topStart + videoRef.current.getBoundingClientRect().height;

      if (scrollY >= topStart && scrollY <= topEnd) {
        setParallaxActive(true);
      } else {
        setParallaxActive(false);
      }

      // parallax for birds
      if (scrollY >= topStart && scrollY <= topEnd) {
        const percentage = (topEnd - scrollY) / videoRef.current.getBoundingClientRect().height;
        setParallaxPercentage((1 - percentage) * 60)
      }
    }
  }, [props.scrollPosition, videoRef]);
  

  return (
    <>
      <div
        className="section section-video bg-orange-red"
        ref={videoRef}
      >
        <div className="content full-page d-flex justify-content-center align-items-center">
          <div className="youtube">
            <div 
              className="bird left hidden-sm"
            >
              <img
                className="bubble"
                src="images/speech-bubbles/animated/WTF_BIN_CHICKEN.gif"
                alt="bin-chicken"
                style={{transform: `translate(2.6rem, -${parallaxActive ? parallaxPercentage * 2: 0}%)`}}
              />
              <img
                className="bird-img"
                src={`${ASSET_URL}/about-bird/bird-left.png`}
                alt="left-bird"
                style={{transform: `translateY(-${parallaxActive ? parallaxPercentage : 0}%)`}}
              />
            </div>
            <div className="video-container">
              <div className="title-content-medium">
                THE <br />
                RUBBISH <br />
                RENEGADES
              </div>
              {!videoClicked && <div className="video-placeholder">
                <img 
                  src="/images/icons/pixels.png" 
                  alt="" 
                  onClick={() => setVideoClicked(true)}
                />
              </div>}
              {videoClicked && <iframe
                width="50%"
                height="50%"
                src={"https://www.youtube.com/embed/w4dYWhkSbTU?autoplay=1"}
                title="YouTube video player"
                frameBorder="0"
                allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                className='revolt-video'
              >
              </iframe>}
              <div className="text-container">
                <div className="bird left">
                  <img
                    className="bubble"
                    src="images/speech-bubbles/animated/WTF_BIN_CHICKEN.gif"
                    alt="bin-chicken"
                  />
                  <img
                    className="bird-img"
                    src={`${ASSET_URL}/about-bird/bird-left.png`}
                    alt="left-bird"
                  />
                </div>
                <div className="video-subtext">
                  The Antihero we hate to love
                </div>
                {/* <div className="bird left extra-small">
                  <img
                    className="bubble"
                    src="images/speech-bubbles/animated/WTF_BIN_CHICKEN.gif"
                    alt="bin-chicken"
                  />
                  <img
                    className="bird-img"
                    src={`${ASSET_URL}/about-bird/bird-left.png`}
                    alt="left-bird"
                  />
                </div> */}
                <div className="bird right">
                  <img
                    className="bubble"
                    src="images/speech-bubbles/animated/PRESS_PLAY.gif"
                    alt="press-play"
                  />
                  <img
                    className="bird-img"
                    src={`${ASSET_URL}/about-bird/bird-right.png`}
                    alt="right-bird"
                  />
                </div>
              </div>
            </div>

            <div 
              className="bird right hidden-sm"
            >
              <img
                className="bubble"
                src="images/speech-bubbles/animated/PRESS_PLAY.gif"
                alt="press-play"
                style={{transform: `translate(-2.6rem, -${parallaxActive ? parallaxPercentage * 2 : 0}%)`}}
              />
              <img
                className="bird-img"
                src={`${ASSET_URL}/about-bird/bird-right.png`}
                alt="right-bird"
                style={{transform: `translateY(-${parallaxActive ? parallaxPercentage : 0}%)`}}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
