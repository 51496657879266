import { debounce } from "lodash";
import React, { useState, useEffect, useMemo } from "react";
import { useRef } from "react";
import { useSelector } from "react-redux";
import { isMobile, isTablet } from "react-device-detect";

import { Link } from "react-router-dom";

import { ReactComponent as Logo } from "../../assets/binkingz.svg";
import "./index.scss";

export const Header = (props) => {
  const [headerColour, setHeaderColour] = useState("black");
  const [isOpenMenuMobile, setIsOpenMenuMobile] = useState(false);
  const {
    refsRoamap,
    refsAbout,
    refsFaqs,
    refsTop,
    refsMint,
    refsBinJuice,
    refsVideo,
    refsTakeover,
    headerVisibility,
  } = useSelector((state) => state.home);
  const refsHeader = useRef(null);
  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const executeScroll = (section) => {
    switch (section) {
      case "about":
        return scrollToRef(refsAbout);
      case "roadmap":
        return scrollToRef(refsRoamap);
      case "faq":
        return scrollToRef(refsFaqs);
      case "top":
        return scrollToRef(refsTop);
      case "mint":
        return scrollToRef(refsMint);
      case "video":
        return scrollToRef(refsVideo);
      case "bin-juice":
        return scrollToRef(refsBinJuice)
      default:
        break;
    }
  };

  useEffect(() => {
    if (props.scrollPosition) {
      const scrollY = props.scrollPosition;
      // top
      const topStart = refsTop.current.offsetTop;
      const topEnd = topStart + refsTop.current.getBoundingClientRect().height;

      // takeover
      // const takeoverStart = refsTakeover.current.offsetTop;
      // const takeoverEnd = takeoverStart + refsTakeover.current.getBoundingClientRect().height;

      // mint boundaries
      const mintStart = refsMint.current.offsetTop;
      const mintEnd =
        mintStart + refsMint.current.getBoundingClientRect().height;

      // video boundaries
      const videoStart = refsVideo.current.offsetTop;
      const videoEnd =
        videoStart + refsVideo.current.getBoundingClientRect().height;


      // bin juice 
      const binJuiceStart = refsBinJuice.current.offsetTop;
      const binJuiceEnd = 
        binJuiceStart + refsBinJuice.current.getBoundingClientRect().height;

      // roadmap
      const roadmapStart = refsRoamap.current.offsetTop;
      const roadmapEnd =
        roadmapStart + refsRoamap.current.getBoundingClientRect().height;
      // faq
      const faqStart = refsFaqs.current.offsetTop;
      const faqEnd = faqStart + refsFaqs.current.getBoundingClientRect().height;

      // artist
      const artistStart = refsAbout.current.offsetTop;
      const artistEnd =
        artistStart + refsAbout.current.getBoundingClientRect().height;

      // top
      if (
        ((scrollY >= topStart &&
        scrollY <= topEnd) || 
        (scrollY >= binJuiceStart 
        && scrollY <= binJuiceEnd)) &&
        headerColour !== "black"
      ) {
        setHeaderColour("black");
      }

      // mint
      if (
        scrollY >= mintStart &&
        scrollY <= mintEnd &&
        headerColour !== "opaque"
      ) {
        setHeaderColour("opaque");
      }

      // video onwards its all white
      if (
        ((scrollY >= roadmapStart && scrollY <= roadmapEnd) ||
          (scrollY >= videoStart && scrollY <= videoEnd) ||
          scrollY >= faqStart) &&
        headerColour !== "white"
      ) {
        setHeaderColour("white");
      }
    }

    if (!isMobile && !isTablet) {
      refsHeader.current.classList.remove("show");
      handleEndScroll();
    }
  }, [props.scrollPosition]);

  useEffect(() => {
    if (!isMobile && !isTablet) {
      refsHeader.current.classList.add("show");
    }
  }, []);

  const handleEndScroll = useMemo(
    () => debounce(() => refsHeader.current.classList.add("show"), 700),
    []
  );
  return (
    <div className={`header ${!headerVisibility ? 'hidden' : 'show'}`} ref={refsHeader}>
      <div className="wrapper">
        <div className="container">
          <div className={`header-top ${headerColour}`}>
            <ul
              className={`menu left ${
                isOpenMenuMobile ? "show" : "hide"
              } ${headerColour}`}
            >
              <li className="menu-item" onClick={() => executeScroll("video")}>
                <Link
                  to="/"
                  className={`link menu-link ${headerColour}`}
                  onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
                >
                  THE REVOLT
                </Link>
              </li>
              <li
                className="menu-item"
                onClick={() => executeScroll("roadmap")}
              >
                <Link
                  to="/"
                  className={`link menu-link ${headerColour}`}
                  onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
                >
                  ROADMAP
                </Link>
              </li>
              <li className="menu-item" onClick={() => executeScroll("faq")}>
                <Link
                  to="/"
                  className={`link menu-link ${headerColour}`}
                  onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
                >
                  FAQS
                </Link>
              </li>
              <li className={`menu-item logo ${headerColour}`}>
                <Link to="/" title="Home page">
                  <Logo className="logo-svg" />
                </Link>
              </li>
              <li className="menu-item" onClick={() => executeScroll("about")}>
                <Link
                  to="/"
                  className={`link menu-link ${headerColour}`}
                  onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
                >
                  ARTIST
                </Link>
              </li>
              <li className={"menu-item"} onClick={() => executeScroll("mint")}>
                <Link
                  to="/"
                  className={`link menu-link ${headerColour}`}
                  onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
                >
                  MINT
                </Link>
              </li>
              <li className="menu-item" onClick={() => executeScroll("bin-juice")}>
                <Link
                  to="/"
                  className={`link menu-link ${headerColour}`}
                  onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
                >
                  <img className='bin-juice-header' src ='./images/bin-juice/bin-juice.png' alt=''/>
                </Link>
              </li>
              {/* }
              <li className="menu-item">
                <button
                  className="btn-connect-wallet truncate bg-red"
                  onClick={() => handleConnectWallet()}
                >
                  {address ? `Connected to ${address}` : "Connect Wallet"}
                </button>
              </li>
              */}
            </ul>
            <div
              className={`mobile-trigger ${isOpenMenuMobile ? "open" : ""}`}
              onClick={() => setIsOpenMenuMobile(!isOpenMenuMobile)}
            >
              <img
                src="/images/icons/menu.svg"
                alt="open menu"
                className="open-menu"
              />
              <img
                src="/images/icons/close.svg"
                alt="close menu"
                className="close-menu"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
