export const SET_REF_ROADMAP = "SET_REF_ROADMAP";
export const SET_REF_ABOUT = "SET_REF_WSB_DAPP";
export const SET_REF_FAQ = "SET_REF_FAQ";
export const SET_REF_TOP = "SET_REF_TOP";
export const SET_REF_MINT = "SET_REF_MINT";
export const SET_REF_VIDEO = "SET_REF_VIDEO";
export const SET_REF_TAKEOVER = "SET_REF_TAKEOVER";
export const SET_REF_BIN_JUICE = "SET_REF_BIN_JUICE";

// header
export const SET_HEADER_VISIBILITY = "SET_HEADER_VISIBILITY";

// metamask
export const SET_WEB3 = "SET_WEB3";
export const SET_ACCOUNT = "SET_ACCOUNT";
