import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ROADMAP_GOALS } from "../../../constants";
import { setRefRoadmap } from "../../../redux/actions/home.actions";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import {ASSET_URL} from "../../../constants.js";

import "./index.scss";

export const SectionRoadmapMobile = (props) => {
  const ref = useRef(null);
  const pinRoadmapRef = useRef(null)
  const imgRoadmapRef = useRef(null);
  const mediaQuery = window.matchMedia('(max-width: 864px)');
  const dispatch = useDispatch();
  const [currentGoal, setCurrentGoal] = useState(-1);
  const placeholderInfo = {
    title: <>BIN CHICKEN TRASH TURKEY TAKEOVER<br />MISSION LAUNCH</>,
    content: 'SCROLL TO SEE MORE'
  }

  const { refsRoamap } = useSelector((state) => state.home)
  useEffect(() => {
    if (mediaQuery.matches) {
      if (
        (refsRoamap && refsRoamap.current && refsRoamap.current.tag !== 'mobile') ||
        (!refsRoamap)
      ) {
        ref.current.tag = 'mobile'
        dispatch(setRefRoadmap(ref));
      }
    }
  }, [ref, mediaQuery, refsRoamap]);

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    timelineFeature()
  }, []);

  const timelineFeature = () => {
    const roadmapTrigger = {
      trigger: "#pin-roadmap",
      start: "top top",
      end: `+=5000`,
      pin: true,
      scrub: 0.5,
    };

    // second part with sticky
    const roadmapTimeline = gsap
      .timeline({
        scrollTrigger: roadmapTrigger,
      })
      .to(".roadmap-img", {
        scale: 3,
        onUpdate: function () {
           imgRoadmapRef.current.style = "transform: none";
        }
      })
      .from(".box-1", {
        duration: 1,
        onUpdate: function () {
          handleTransformRoadmap(1);
          setCurrentGoal(0);
        },
      })
      .from(".box-2", {
        duration: 1,
        onUpdate: function () {
          handleTransformRoadmap(2);
          setCurrentGoal(1);
        },
      })
      .from(".box-3", {
        duration: 1,
        onUpdate: function () {
          handleTransformRoadmap(3);
          setCurrentGoal(2);
        },
      })
      .from(".box-4", {
        duration: 1,
        onUpdate: function () {
          handleTransformRoadmap(4);
          setCurrentGoal(3);
        },
      })
      .from(".box-5", {
        duration: 1,
        onUpdate: function () {
          handleTransformRoadmap(5);
          setCurrentGoal(4);
        },
      })
      .to({}, {
        duration: 1, // dummy delay
      });

      

    let masterTimeline = gsap.timeline().add(roadmapTimeline);
  };

  const handleTransformRoadmap = (number) => {
    switch (number) {
      case 1:
        imgRoadmapRef.current.style = "transform: scale(3) translate(19%, 10%)";
        break;

      case 2:
        imgRoadmapRef.current.style = "transform: scale(3) translate(6%, -30%)";
        break;

      case 3:
        imgRoadmapRef.current.style =
          "transform: scale(3) translate(-17%, -32%)";
        break;

      case 4:
        imgRoadmapRef.current.style =
          "transform: scale(3) translate(-27%, -10%)";
        break;

      case 5:
        imgRoadmapRef.current.style =
          "transform: scale(3) translate(-32%, 12%)";
        break;

      default:
        imgRoadmapRef.current.style = "transform: scale(1) translate(0, 0)";
        break;
    }
  };

  return (
    <>
      <div
        className="section section-roadmap-mobile bg-yellow-orange"
        ref={ref}
      >
        <div className="container">
          <div className="content">
            <div className="title-content">
              <img src="/images/landing/BinKingz – The Flappening.png" alt="" />
              <div className="header-content">
                The bin chicken is migrating for a full metaverse takeover
              </div>
              <div className="description-content">
                Our mission is to step our tainted talons where no bird has gone
                before:
                <br />
                into each and every virtual land. The world will never escape
                the brutal
                <br />
                beak of the Bin chicken, even in the metaverse. No bin is safe.
                <br />
                <br />
                It's time to show the world what we're about.
              </div>
            </div>
            <div className="roadmap-trigger">
              <div className="roadmap" id="pin-roadmap" ref={pinRoadmapRef}>
                <div className="roadmap-goal-modal dotted-box">
                  <div className="goal-title">
                    {currentGoal >= 0 ? ROADMAP_GOALS[currentGoal].title : placeholderInfo.title}
                  </div>
                  <div className="goal-description">
                    {currentGoal >= 0 ? ROADMAP_GOALS[currentGoal].content : placeholderInfo.content}
                  </div>
                </div>
                <div className="bk-roadmap-parent">
                  <div className="bk-roadmap-child">
                    <div className="box box-1"></div>
                    <div className="box box-2"></div>
                    <div className="box box-3"></div>
                    <div className="box box-4"></div>
                    <div className="box box-5"></div>
                    <img
                      ref={imgRoadmapRef}
                      src={`${ASSET_URL}/roadmap/mobile-roadmap.png`}
                      alt=""
                      className="roadmap-img"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
