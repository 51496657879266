import Web3 from 'web3'
import { APP_ENVIRONMENTS } from '../config/config.js'
import {
  CONTRACT_ADDRESS, PRICE, ABI_ARRAY, ABI_ARRAY_MOCK
} from '../contracts/contract.js'


const createContractObject = (abi, address) => {
  const web3 = new Web3(APP_ENVIRONMENTS.ETHEREUM_PROVIDER) // CHANGE 
  return new web3.eth.Contract(abi, address)
}

export default class BinKingzRequest {
  BIN_KINGZ_CONTRACT

  constructor() {
    this.BIN_KINGZ_CONTRACT = createContractObject(ABI_ARRAY, CONTRACT_ADDRESS)
  }

  async getTotalSupply() {
    try {
      const totalSupply = await this.BIN_KINGZ_CONTRACT
        .methods.totalSupply().call()
      return totalSupply
    } catch (err) {
      return 0
    }
  }

  async getUserBalance(_address) {
    try {
      const userBalance = await this.BIN_KINGZ_CONTRACT
        .methods.balanceOf(_address || '').call()
      return userBalance
    } catch (err) {
      return 0
    }
  }

  async getSalesActive() {
    try {
      const salesActive = await this.BIN_KINGZ_CONTRACT
        .methods.salesActive().call()
      return salesActive
    } catch (err) {
      return false
    }
  }

}