import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRefMint } from "../../../redux/actions/home.actions.js";
import { toast } from 'react-toastify'
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import "./index.scss";

import Grid from "../../../grid";
import { ASSET_URL } from "../../../constants.js";
import { RANDOM_BUBBLE_LEFT, RANDOM_BUBBLE_RIGHT } from "../../../constants.js";
import { preloadImages } from "../../../utils";

import Web3 from 'web3'
import BinKingzRequest from '../../../requests/BinKingzRequest.js'
import { CONTRACT_ADDRESS, PRICE, ABI_ARRAY, ABI_ARRAY_MOCK } from '../../../contracts/contract.js'

export const SectionMint = (props) => {
  const GALLERY_ITEMS = 10;

  const gridItemsRefs = useRef(null);
  // ref
  const dispatch = useDispatch();
  // modals
  const mintModalRef = useRef(null);
  const aboutProjectRef = useRef(null);
  // gallery ref
  const galleryRef = useRef(null);

  // random bubbles (choose 3)
  const [selectedBubblesLeft, setSelectedBubblesLeft] = useState([]);
  // const [selectedBubblesRight, setSelectedBubblesRight] = useState([]);

  // MINT
  const { ethereum } = window
  const { isMainnet, connecting, connectWallet} = props

  // account
  const homeState = useSelector((state) => state.home);
  const account = homeState && homeState.account ? homeState.account : null;

  // supply
  const MAX_SUPPLY = 6841 // PUBLIC
  const MAX_PER_WALLET = 25 // PUBLIC
  const [totalSupply, setTotalSupply] = useState(null)
  const [salesActive, setSalesActive] = useState(false)
  const [soldOut, setSoldOut] = useState(false)
  const [balanceOf, setBalanceOf] = useState(null)


  // mint
  const [claimStatus, setClaimStatus] = useState(null)
  const [claimAmount, setClaimAmount] = useState(1)
  const [claiming, setClaiming] = useState(false)

  const binKingzRequest = new BinKingzRequest()

  useEffect(() => {
    handleGetInfo()
  }, [account])

  // METAMASK
  const handleConnectWallet = async () => {
    if (!connecting) {
      await connectWallet()
    }
  }

  // check amounts
  const handleGetInfo = async () => {
    const userBalance = await binKingzRequest.getUserBalance(account)
    const totalSupply = await binKingzRequest.getTotalSupply()
    const salesActive = await binKingzRequest.getSalesActive()
    setBalanceOf(userBalance)
    setTotalSupply(totalSupply)
    setSalesActive(salesActive)
  }
  
  

  // claim amount
  const handleChangeClaimAmount = (increase) => {
    if (!claiming) {
      if (increase) {
        if (claimAmount < MAX_PER_WALLET - balanceOf) {
          setClaimAmount(claimAmount + 1)
        }
      } else {
        if (claimAmount > 1) {
          setClaimAmount(claimAmount - 1)
        }
      }
    }
  }

  const handleMint = async () => {
    if (claiming) return

    setClaiming(true)
    try {
      if (!account) {
        setClaiming(false)
        return toast.error('Please connect your wallet first')
      }
      if (!isMainnet) {
        setClaiming(false)
        return toast.error('Please connect to the ethereum mainnet')
      }

      if (!salesActive) {
        setClaiming(false)
        return toast.error('Sales not active, cannot claim.')
      }

      if (claimAmount > MAX_PER_WALLET - balanceOf) {
        setClaiming(false)
        return toast.error(`Can only claim up to ${MAX_PER_WALLET - balanceOf} Bin Kingz`)
      }

      const web3 = new Web3(window.ethereum)
      const ethBalance = await web3.eth.getBalance(account)
      const ethBalanceParsed = Number(web3.utils.fromWei(ethBalance, 'ether'))
      const priceToClaim = Number(PRICE) * claimAmount
      

      if (priceToClaim > ethBalanceParsed) {
        setClaiming(false)
        return toast.error(`Insufficient ETH to mint ${claimAmount} Bin Kingz.`)
      }

      // all good
      const contract = new web3.eth.Contract(
        ABI_ARRAY,
        CONTRACT_ADDRESS
      )
      const _price = web3.utils.toWei(`${priceToClaim}`, 'ether')
      const estimateGas = await contract.methods
        .claim(claimAmount)
        .estimateGas({
          from: account,
          value: _price
        })
      

      contract.methods
        .claim(claimAmount)
        .send({
          from: account,
          value: _price,
          gasLimit: Math.floor((estimateGas * 150 / 100))
        })
        .on('sent', (send) => {
          setClaimStatus(`Minting ${claimAmount} Bin Kingz...`)
        })
        .on('receipt', (receipt) => {
          if (receipt && receipt.transactionHash) {
            setClaimStatus(<>
              <p>
                Minted {claimAmount}:
                &nbsp;
                <a href={`https://etherscan.io/tx/${receipt.transactionHash}`}>
                  Claim Txn
                </a>
              </p>
            </>)
            setClaiming(false)
            toast.success(<p>
              Successfully minted {claimAmount} Bin King(z)!
              &nbsp;
              <a href={`https://etherscan.io/tx/${receipt.transactionHash}`}>
                Click here to view your txn.
              </a>
            </p>)
          }
          handleGetInfo()
        })
        .on('error', (err) => {
          setClaiming(false)
          setClaimStatus(err?.message || 'An error has occured when minting.')
          console.log('mint err', err?.message)
          toast.error(err?.message || 'An error has occured when minting.')
          handleGetInfo()
        })
    } catch (err) {
      console.log(err)
      setClaimStatus(err?.message || 'An error has occured when minting.')
      toast.error(err?.message || 'An error occured when minting, please try again.')
      setClaiming(false)
      handleGetInfo()
    }
  }

  // GSAP RELATED
  gsap.registerPlugin(ScrollTrigger);
  gsap.config({
    nullTargetWarn: false,
  });

  // old bubble code
  const setRandomBubbles = (parent, setter) => {
    const selected = [];
    let indexes = [...Array(parent.length).keys()];
    for (const i of [...Array(3).keys()]) {
      const randomIndex = Math.floor(Math.random() * indexes.length);
      selected.push(indexes[randomIndex]);
      indexes.splice(randomIndex, 1);
    }
    setter(selected.map((x) => parent[x]));
  };

  // gallery master function
  const galleryEffect = (galleryId, imageClass, galleryRef) => {
    // const gallery = galleryRef.current

    const looper = document.querySelectorAll(`${galleryId} ${imageClass}`);
    const imageHeight = looper[0].offsetHeight;
    const totalHeight = looper.length * imageHeight;
    const dirFromBottom = `-=${totalHeight}`;

    let mod = gsap.utils.wrap(0, totalHeight); // height related

    const randomX = gsap.utils.random(-60, 60, true);

    // set boundaries
    gsap.set(looper, {
      y: function (i) {
        return i * imageHeight;
      },
    });

    // stagger x
    looper.forEach((el) => {
      let randX = randomX();
      randX = !randX ? randomX() : randX;

      // set
      gsap.set(el, {
        xPercent: randX,
      });
    });

    // magic happen here
    const timeline = gsap.timeline().to(looper, {
      y: dirFromBottom,
      modifiers: {
        y: (y) => `${mod(parseFloat(y))}px`,
      },
      duration: 25,
      ease: "none",
      repeat: -1,
    });
  };

  useEffect(() => {
    dispatch(setRefMint(mintModalRef));

    if (!selectedBubblesLeft.length) {
      setRandomBubbles(RANDOM_BUBBLE_LEFT, setSelectedBubblesLeft);
    }

    // if (!selectedBubblesRight.length) {
    //   setRandomBubbles(RANDOM_BUBBLE_RIGHT, setSelectedBubblesRight);
    // }
  }, [selectedBubblesLeft]);

  // mint modal gsap
  useEffect(() => {
    // register gsap once
    const targets = gsap.utils.toArray(
      "#content-mint",
      "#mint-modal",
      "#about-modal"
    );
    if (targets.length) {
      gsap
        .timeline({
          scrollTrigger: {
            trigger: "#content-mint",
            start: "top top",
            end: "+=2800",
            scrub: 0.5,
            pin: true,
          },
        })
        .to("#mint-modal", {
          y: "-250%",
          ease: "power3.inOut",
        })
        .from("#about-modal", { y: "+200%", ease: "power3.inOut" }, "<0.3")
        .to({}, {
            duration: 0.3,
          }
        );
    }
  }, []);

  // gsap gallery thing
  useEffect(() => {
    galleryEffect("#gallery-first", ".image-first", galleryRef);
    galleryEffect("#gallery-second", ".image-second", galleryRef);
    galleryEffect("#gallery-third", ".image-third", galleryRef);
  }, [galleryRef]);


  const formatAccount = () => {
    return <>
      <div className='account-info'>
        <span className='account-row'>
          Wallet:&nbsp;
          <span>
            {`${account.slice(0, 6)}...${account.slice(-6)}`}
          </span>
        </span>
        <span className='account-row'>
          Owned:&nbsp;
          <span>
            {balanceOf !== null ? Number(balanceOf) : '--'} / {MAX_PER_WALLET}
          </span>
        </span>

        <span className='account-row'>
          Sold:&nbsp;
          <span>
            {totalSupply !== null ? Number(totalSupply) : '--'} / {MAX_SUPPLY}
          </span>
        </span>

        {claimStatus && <span className='account-row claim'>
          {claimStatus}
        </span>}
      </div>
    </>
  }

  const mintButtonJSX = () => {

    if (totalSupply >= MAX_SUPPLY) {
      return (
        <div className='mint-section-button sold-out'>
          Sold Out
        </div>
      )
    }

    if (!salesActive) {
      return (
        <div className='mint-section-button sales-inactive'>
          Sales Inactive
        </div>
      )
    }

    if (balanceOf >= MAX_PER_WALLET) {
      return (
        <div className='mint-section-button max-reached'>
          Minted Max
        </div>
      )
    }

    return <>
        <div className={`mint-button ${claiming ? 'processing' : ''}`} onClick={() => handleMint()}>
          <img src="/images/mint/mint-button.png" />
        </div>
        <div className='amount-input'>
          <i 
            className={`fa-solid fa-arrow-down ${claimAmount <= 1 ? 'disabled' : ''}`}
            onClick={() => handleChangeClaimAmount(false)}
          >
          </i>
          <span className='claim-amount'>
            x {claimAmount}
          </span>
          <i 
            className={`fa-solid fa-arrow-up ${claimAmount >= MAX_PER_WALLET - balanceOf ? 'disabled' : ''}`}
            onClick={() => handleChangeClaimAmount(true)}
          ></i>
        </div>
      </>
  }


  return (
    <div className="section section-mint bg-blue" ref={mintModalRef}>
      <div className="gallery" ref={galleryRef}>
        <div id="gallery-first" className="gallery-col first">
          {Array.from(Array(GALLERY_ITEMS).keys()).map((x) => {
            return (
              <img
                className="image-first"
                src={`${ASSET_URL}/mint/assets/${x + 1}.jpg`}
                alt=""
                key={x + 1}
              />
            );
          })}
        </div>
        <div id="gallery-second" className="gallery-col second">
          {Array.from(Array(GALLERY_ITEMS).keys()).map((x) => {
            return (
              <img
                className="image-second"
                src={`${ASSET_URL}/mint/assets/${x + 1 + GALLERY_ITEMS}.jpg`}
                alt=""
                key={x + 1 + GALLERY_ITEMS}
              />
            );
          })}
        </div>
        <div id="gallery-third" className="gallery-col third">
          {Array.from(Array(GALLERY_ITEMS).keys()).map((x) => {
            return (
              <img
                className="image-third"
                src={`${ASSET_URL}/mint/assets/${
                  x + 1 + GALLERY_ITEMS * 2
                }.jpg`}
                alt=""
                key={x + 1 + GALLERY_ITEMS * 2}
              />
            );
          })}
        </div>
      </div>
      <div id="content-mint" className="content">
        <div className="center text-center first">
          <div id="mint-modal" className="center-modal">
            <div className="modal-header">RECRUIT YOUR BIN KINGZ</div>
            <div className="title-content-medium">
              JOIN THE REVOLTING REVOLT
            </div>
            {account && formatAccount()}
            {!account 
              ? (<button 
                  className={`btn class-btn mint-section-button connect-wallet ${connecting ? 'processing' : ''}`}
                  onClick={() => handleConnectWallet()}
                >
                  {connecting
                    ? 'Check Metamask'
                    : 'Connect Wallet'
                  }
                </button>)
              : mintButtonJSX()
            }
          </div>
          <div id="about-modal" className="center-modal about">
            <div className="modal-description">
              Rubbish renegades. The smelly flamingo. The Australian white ibis
              (Threskiornis molucca) is a modern Aussie icon.
              <br />
              <br />
              And we’re migrating these filthy flappers into the Metaverse.
              <br />
              <br />
              No other animal encompasses the nature of Australia like the bin
              chicken. So we’re building an avian army to ambush the Metaverse
              in a full trash turkey takeover.
              <br />
              <br />
              The world won’t know what hit ‘em
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
