import React, { useState, useRef, useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

import { ASSET_URL } from "../../../constants.js";
import { setRefTakeover, setHeaderVisibility } from "../../../redux/actions/home.actions";

import "./index.scss";

export const SectionVideoTakeOver = () => {

  const [sequenceComplete, setSequenceComplete] = useState(false);
  const [isOpenVideo, setIsOpenVideo] = useState(false);
  const videoRef = useRef(null);

  const takeoverRef = useRef(null);
  const birdImageRef = useRef(null);

  const dispatch = useDispatch();
  
  const openVideoPopup = (action) => {
    // disable body scroll when popup opening
    document.body.style.overflow = "hidden";
    if (action === "close") {
      document.body.style.overflow = "initial";
    }
    setIsOpenVideo(!isOpenVideo);
    dispatch(setHeaderVisibility(isOpenVideo));
    videoRef.current.play();
  };

  useEffect(() => {

    const takeoverTop = takeoverRef.current.offsetTop;
    const takeoverEnd = takeoverTop + takeoverRef.current.getBoundingClientRect().height;

    gsap.registerPlugin(ScrollTrigger);

    gsap.timeline({
      scrollTrigger: {
        trigger: '.takeover-container',
        start: 'top top',
        end: '+=150%',
        scrub: 0.5,
        pin: true,
      },
    })
      .fromTo('.bird-image', 
      {
        width: "30%",
      },
      {
        width: "100%",
        x: "-16.4%",
        y: "-65%",
        scale: 2,
        onUpdate: function() {
          const progress = this.progress();
          const birdWidth = Number(birdImageRef.current.style.width.replace('%', ''))
          if (progress > 0.8 && birdWidth >= 95) {
            setSequenceComplete(true)
          } else {
            setSequenceComplete(false);
          }
        }
      })
      .to({}, {
        duration: 0.3
      })

  }, [takeoverRef, birdImageRef])

  useEffect(() => {
    dispatch(setRefTakeover(takeoverRef));
    // dispatch(setHeaderVisibility(true)); // initially must be visible
    if (!isOpenVideo) { // prevent video from randomly playing
      videoRef.current.pause();
    }
  }, [takeoverRef, videoRef]) 

  return (
    <>
      <div className="section section-video-takeover bg-yellow-orange" ref={takeoverRef}>
        <div className="container takeover-container">
          <div className="content full-page d-flex justify-content-center align-items-center">
            <div className="takeover d-flex flex-column justify-content-start">
              <div className="bird">
                <img 
                  className={`bird-image ${sequenceComplete ? 'active' : ''}`}
                  src={`${ASSET_URL}/takeover/binkingz-watch.png`} 
                  alt="" 
                  ref={birdImageRef}
                  onLoad={() => ScrollTrigger.refresh()}
                />
              </div>
              <div className="tv-container">
                <div className="button-container">
                  <div 
                    className={`video-popup-button  ${sequenceComplete ? 'active' : ''}`}
                    onClick={(e) => openVideoPopup(e)}
                  >
                    &emsp;PLAY&emsp;
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={`video-popup ${isOpenVideo ? "show" : ""}`}>
          <div className="overlay-background"></div>
          <div className="video">
            <div className="youtube-box">
              <img
                src="/images/icons/close.png"
                alt="close"
                title="Close"
                className="btn-close cursor-pointer"
                onClick={() => {
                  openVideoPopup("close");
                  videoRef.current.pause();
                }}
              />
              <video className="video-container" autoPlay controls ref={videoRef}>
                <source src="https://binkingz-assets.s3.ap-southeast-2.amazonaws.com/web/videos/binchookHD_w_Title.mp4" type="video/mp4" />
              </video>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
