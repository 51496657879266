import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setRefAbout } from "../../../redux/actions/home.actions";

import { ASSET_URL } from '../../../constants.js';
import "./index.scss";

export const SectionAbout = (props) => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [filterApplied, setFilterApplied] = useState(false);

  // create rgb image
  const RGBImages = (image) => {
    const colours = ["r", "g", "b"];
    // append the filters appropriately
    const images = colours.map((colour) => {
      const img = image.cloneNode();
      img.classList.add(`__${colour}`);
      return img;
    });
    return images;
  };

  // applying filter
  const applyVHSFilter = (image) => {
    // doesnt support
    if (
      !CSS.supports("mix-blend-mode", "screen") ||
      !CSS.supports("filter", "url()")
    )
      return;

    const container = document.createElement("div");
    const images = RGBImages(image);

    // create the compound filter
    images.forEach((image) => {
      container.appendChild(image);
    });

    // replace image with the container filter
    container.classList.add("vhs-filter");
    image.replaceWith(container);
  };

  useEffect(() => {
    dispatch(setRefAbout(ref));
    if (!filterApplied) {
      const images = document.querySelectorAll(".vhs-filter");
      images.forEach((image) => applyVHSFilter(image));
      setFilterApplied(true);
    }
  }, []);

  return (
    <>
      <div className="section section-about bg-black overflow-hidden" ref={ref}>
        <div className="container">
          <div className="content">
            <div className="box-content first">
              <div className="left">
                <div className="title-content-medium font-bold">
                  SCOTT MARSH
                </div>
                <p>
                  I’ve always strived to remain autonomous and not rely on
                  galleries, grants or any third-parties for funding.
                </p>
                <p>
                  So, when you’re willing to take risks, the creative
                  constraints are usually financial.
                </p>
                <p>
                  Watching the NFT space since early this year, I’ve seen how
                  the technology can transform the ephemeral nature of the
                  street works I create, while bringing a whole new dimension to
                  my work going forward.
                </p>
                <p>
                  This project will ensure my future work stays genuine, while
                  funding a number of large-scale projects I’ve had on the back
                  burner for some years.
                </p>

                <img
                  className="img-scott-marsh"
                  src={`${ASSET_URL}/artist-about/scott-signature.svg`}
                  alt=""
                />
              </div>

              <div className="right">
                <img
                  className="artist-flowers vhs-filter"
                  src={`${ASSET_URL}/artist-about/ArtistFlowers2.jpg`}
                  alt=""
                />
                <div className="follow-scott-banner">
                  <span className="follow-scott-text">FOLLOW SCOTT</span>
                  <span>
                    <a
                      href="https://www.instagram.com/scottie.marsh/"
                      target="_blank"
                    >
                      <i className="fa-brands fa-facebook-f"></i>
                    </a>
                  </span>
                  <span>
                    <a href="https://twitter.com/Scottie_marsh" target="_blank">
                      <i className="fa-brands fa-instagram"></i>
                    </a>
                  </span>
                  <span>
                    <a href="https://scottmarsh.com.au/" target="_blank">
                      <i className="fa-brands fa-twitter"></i>
                    </a>
                  </span>
                </div>
              </div>
            </div>

            <div className="box-content second">
              <img
                className="vhs-filter"
                src={`${ASSET_URL}/artist-about/scott-marsh.png`}
              />
            </div>

            <div className="box-content last">
              <div className="left">
                <img
                  className="artist-bin-bird vhs-filter"
                  src={`${ASSET_URL}/artist-about/ArtistPageMural.jpg`}
                  alt=""
                />
              </div>
              <div className="right">
                <div className="title-about-scott">MORE ABOUT SCOTT</div>
                <p>
                  Scott Marsh is one of Australia's most iconic and infamous
                  street artists. With a deep history in graffiti culture, he’s
                  recognised internationally for his signature, politically
                  inspired and newsworthy mural pieces.
                </p>
                <p>
                  From tagging Sydney trains at 12 to becoming a finalist in
                  both the Moran Portrait Prize and Archibald Prize, Scott
                  confronts the common perceptions around street art and
                  graffiti, using traditional art techniques to prove that fine
                  art can exist and succeed beyond oil and canvas.
                </p>
                <p>
                  While some of his interpretations may be contentious, the
                  overwhelming majority of Australians admire and encourage his
                  work, with each piece attracting droves of spectators and
                  transforming mundane buildings into shrines of hope,
                  resilience and social change.
                </p>
                <p className="content_scott">
                  Scott’s work has made news headlines globally with his iconic
                  pieces including{" "}
                  <a
                    target="_blank"
                    href="https://www.smh.com.au/entertainment/art-and-design/artist-scott-marsh-paints-over-kanye-loves-kanye-mural-claims-he-was-paid-100000-20160411-go38fv.html"
                  >
                    Kanye Loves Kanye
                  </a>
                  ,{" "}
                  <a
                    target="_blank"
                    href="https://www.theguardian.com/australia-news/2017/nov/19/vandalised-george-michael-mural-sydney-makeover"
                  >
                    Saint George
                  </a>
                  ,{" "}
                  <a
                    target="_blank"
                    href="https://www.ladbible.com/news/latest-aussie-artist-unveils-savage-donald-trump-graffiti-in-sydney-20201108"
                  >
                    Trump Obese Turtle
                  </a>
                  ,{" "}
                  <a
                    target="_blank"
                    href="https://www.9news.com.au/national/bushfire-news-hawaiian-themed-scott-morrison-mural-painted-over/b6d6ec0a-060e-419f-8a3a-17f095bfa810"
                  >
                    Merry Crisis
                  </a>{" "}
                  and{" "}
                  <a
                    target="_blank"
                    href="https://www.abc.net.au/news/2021-06-04/nsw-police-pushed-for-black-lives-matter-mural-to-be-removed/13363898"
                  >
                    A Symbol of Pain and Frustration
                  </a>
                  .
                </p>
              </div>
            </div>
          </div>
        </div>
        <svg className="svg-filter">
          <filter id="r">
            <feColorMatrix
              type="matrix"
              values="1 0 0 0 0  0 0 0 0 0  0 0 0 0 0  0 0 0 1 0 "
            ></feColorMatrix>
          </filter>
          <filter id="g">
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0  0 1 0 0 0  0 0 0 0 0  0 0 0 1 0 "
            ></feColorMatrix>
          </filter>
          <filter id="b">
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0  0 0 0 0 0  0 0 1 0 0  0 0 0 1 0 "
            ></feColorMatrix>
          </filter>
        </svg>
      </div>
    </>
  );
};
