import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setRefRoadmap } from "../../../redux/actions/home.actions";

import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ExpoScaleEase } from "gsap/EasePack";

import { ReactComponent as Path1 } from "../../../assets/paths/path-1.svg";
import { ReactComponent as Path2 } from "../../../assets/paths/path-2.svg";
import { ReactComponent as Path3 } from "../../../assets/paths/path-3.svg";
import { ReactComponent as Path4 } from "../../../assets/paths/path-4.svg";
import { ReactComponent as Path5 } from "../../../assets/paths/path-5.svg";

import { ROADMAP_GOALS, ASSET_URL } from "../../../constants.js";

import "./index.scss";

export const SectionRoadmap = (props) => {
  const ref = useRef(null);
  const mapRef = useRef(null);
  const dispatch = useDispatch();
  const mediaQuery = window.matchMedia("(max-width: 1350px)");

  const smallQuery = window.matchMedia("(max-width: 992px)");
  const mediumQuery = window.matchMedia("(max-width: 1080px)");
  const largeQuery = window.matchMedia("(max-width: 1450px)");

  const roadmapSmallCheckQuery = window.matchMedia('(max-width: 864px)');

  const { refsRoamap } = useSelector((state) => state.home)

  // display in modal
  const [currentGoal, setCurrentGoal] = useState(0);
  // svg paths
  const [pathOnePercentage, setPathOnePercentage] = useState(0);

  const [refSet, setRefSet] = useState(false)


  useEffect(() => {
    setTimeout(() => {
      const event = document.createEvent("UIEvents");
      event.initUIEvent("resize", true, false, window, 0);
      window.dispatchEvent(event);

      // window.dispatchEvent(new Event('resize'))
    }, 0);

    window.addEventListener('onload', largeTimelineFeature)
    window.addEventListener("resize", () => ScrollTrigger.refresh());
    return () => {
      window.removeEventListener('onload', largeTimelineFeature)
      window.removeEventListener("resize", () => ScrollTrigger.refresh());
    };
  }, []);
  
  useEffect(() => {
    if (!roadmapSmallCheckQuery.matches) {
      if (
        (refsRoamap && refsRoamap.current && refsRoamap.current.tag !== 'regular') ||
        (!refsRoamap)) {
        ref.current.tag = 'regular'
        dispatch(setRefRoadmap(ref));
      }
    }
    // }
  }, [ref, roadmapSmallCheckQuery, refsRoamap]);

  // map padding
  const determinePinStart = () => {
    if (smallQuery.matches) {
      return -18;
    }
    if (mediumQuery.matches) {
      return -8;
    }
    // if (largeQuery.matches) {
    // }
    return 2;
  };

  const drawSVG = (id, progress, setter) => {
    const pathId = "path" + id;
    const maskId = "mask" + id;

    const path = document.getElementById(pathId);
    const mask = document.getElementById(maskId);
    if (!path || !mask) return;

    const length = path.getTotalLength();

    mask.style.strokeDasharray = length;
    mask.style.strokeDashoffset = length;

    const percentage = length * progress;
    mask.style.strokeDashoffset = length - percentage;
  };

  const handleActiveBox = (boxClass, progress) => {
    const element = document.querySelector(boxClass);
    if (!element) return;
    const classList = element.classList;
    if (progress > 0.7) {
      element.classList.add("active");
    } else {
      element.classList.remove("active");
    }
  };

  // large-screen-timeline
  const largeTimelineFeature = () => {
    const height = mapRef.current.offsetHeight
    const roadmapTrigger = {
      trigger: ".roadmap-image",
      start: `${determinePinStart()}% top`, // start here
      // issue: on first load image height not accounted for
      // solution: set arbitrary height if thats the case, else use normal * 3
      end: `+=3500`, 
      pin: ".pin-container",
      scrub: 1,
    };

    const firstTrigger = {
      trigger: ".box-one",
      start: "-=100 top",
      end: "+=400",
      scrub: 1,
    };

    // top part
    const firstTimeline = gsap
      .timeline({
        scrollTrigger: firstTrigger,
      })
      .from(".path-1", {
        opacity: 0,
        onUpdate: function () {
          drawSVG(1, this.progress(), setPathOnePercentage);
        },
      })
      .from(".roadmap-image", {
        opacity: 0,
      })
      .from(".roadmap-goal-modal", {
        opacity: 0,
      })
      .from(".box-1", {
        opacity: 0,
        onUpdate: function () {
          handleActiveBox(".box-1", this.progress());
        },
        onComplete: function () {
          setCurrentGoal(0);
        },
      })
      .from(".art", {
        opacity: 0,
      });

    // second part with sticky
    const roadmapTimeline = gsap
      .timeline({
        scrollTrigger: roadmapTrigger,
      })
      .from(".path-2", {
        opacity: 0,
        onUpdate: function () {
          drawSVG(2, this.progress(), setPathOnePercentage);
        },
      })
      .from(".box-2", {
        opacity: 0,
        onUpdate: function () {
          handleActiveBox(".box-2", this.progress());
        },
        onComplete: function () {
          setCurrentGoal(1);
        },
      })
      .from(".balaclava", {
        opacity: 0,
      })
      .from(".path-3", {
        opacity: 0,
        onUpdate: function () {
          drawSVG(3, this.progress(), setPathOnePercentage);
        },
      })
      .from(".box-3", {
        opacity: 0,
        onUpdate: function () {
          handleActiveBox(".box-3", this.progress());
        },
        onComplete: function () {
          setCurrentGoal(2);
        },
      })
      .from(".bin", {
        opacity: 0,
      })
      .from(".path-4", {
        opacity: 0,
        onUpdate: function () {
          drawSVG(4, this.progress(), setPathOnePercentage);
        },
      })
      .from(".box-4", {
        opacity: 0,
        onUpdate: function () {
          handleActiveBox(".box-4", this.progress());
        },
        onComplete: function () {
          setCurrentGoal(3);
        },
      })
      .from(".rally", {
        opacity: 0,
      })
      .from(".path-5", {
        opacity: 0,
        onUpdate: function () {
          drawSVG(5, this.progress(), setPathOnePercentage);
        },
      })
      .from(".box-5", {
        opacity: 0,
        onUpdate: function () {
          handleActiveBox(".box-5", this.progress());
        },
        onComplete: function () {
          setCurrentGoal(4);
        },
      })
      .from(".funding", {
        opacity: 0,
      })
      .from(
        {},
        {
          duration: 1, // dummy delay
        }
      );

    let masterTimeline = gsap
      .timeline()
      .add(firstTimeline)
      .add(roadmapTimeline);
    ScrollTrigger.refresh();
  };

  // small-screen-timeline

  // bread and butter of this entire section
  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger);
    largeTimelineFeature();
  }, [mapRef]);

  return (
    <>
      <div className="section section-roadmap bg-yellow-orange" ref={ref}>
        <div className="container">
          <div className="content">
            <div className="title-content">
              <img src="/images/landing/BinKingz – The Flappening.png" alt="" />
              <div className="header-content">
                The bin chicken is migrating for a full metaverse takeover
              </div>
              <div className="description-content">
                Our mission is to step our tainted talons where no bird has gone
                before:
                <br />
                into each and every virtual land. The world will never escape
                the brutal
                <br />
                beak of the Bin chicken, even in the metaverse. No bin is safe.
                <br />
                <br />
                It's time to show the world what we're about.
              </div>
              <div className="dotted-box box-one">
                Bin chicken trash turkey takeover
                <br />
                Mission launch
              </div>
            </div>

            <div className="outer-container">
              <div className="pin-container">
                {/* path 1 */}
                <div className="paths-box one">
                  <Path1 className="path path-1" />
                </div>

                {/* path 2 */}
                <div className="paths-box two">
                  <Path2 className="path path-2" />
                </div>

                {/* path 3 */}
                <div className="paths-box three">
                  <Path3 className="path path-3" />
                </div>

                {/* path 4 */}
                <div className="paths-box four">
                  <Path4 className="path path-4" />
                </div>

                {/* path 4 */}
                <div className="paths-box five">
                  <Path5 className="path path-5" />
                </div>

                <div className="roadmap">
                  {/* display box */}
                  <div className="roadmap-goal-modal dotted-box actionable">
                    <div className="goal-title">
                      {ROADMAP_GOALS[currentGoal].title}
                    </div>
                    <div className="goal-description">
                      {ROADMAP_GOALS[currentGoal].content}
                    </div>
                  </div>

                  {/* main part */}
                  <div className="roadmap-image" >
                    <img 
                      src={`${ASSET_URL}/roadmap/BinKingz-Map.png`}
                      alt="roadmap"  
                      ref={mapRef}
                      onLoad={() => ScrollTrigger.refresh()}
                    />
                  </div>

                  {/* box 1 */}
                  <div
                    className="roadmap-box dotted-box actionable box-1"
                    onClick={() => setCurrentGoal(0)}
                  >
                    HERO THE <br /> BIN KINGZ INSIGNIA
                  </div>

                  {/* box 2 */}
                  <div
                    className="roadmap-box dotted-box box-2"
                    onClick={() => setCurrentGoal(1)}
                  >
                    COLLECT YOUR <br /> STAUNCH UNIFORM
                  </div>

                  {/* box 3 */}
                  <div
                    className="roadmap-box dotted-box box-3"
                    onClick={() => setCurrentGoal(2)}
                  >
                    BIN KINGZ TREASURE HUNT
                  </div>

                  {/* box 4 */}
                  <div
                    className="roadmap-box dotted-box box-4"
                    onClick={() => setCurrentGoal(3)}
                  >
                    RALLY THE TROOPS
                  </div>

                  {/* box 4 */}
                  <div
                    className="roadmap-box dotted-box box-5"
                    onClick={() => setCurrentGoal(4)}
                  >
                    FUNDING THE FIGHT
                  </div>

                  {/* images section */}
                  <div className="map-image art">
                    <img src="/images/roadmap/art.png" alt="" />
                  </div>

                  <div className="map-image balaclava">
                    <img src="/images/roadmap/balaclava.png" alt="" />
                  </div>

                  <div className="map-image bin">
                    <img src="/images/roadmap/bin.png" alt="" />
                  </div>

                  <div className="map-image bin">
                    <img src="/images/roadmap/bin.png" alt="" />
                  </div>

                  <div className="map-image rally">
                    <img src="/images/roadmap/rally.png" alt="" />
                  </div>

                  <div className="map-image funding">
                    <img src="/images/roadmap/funding.png" alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
