import React, { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile, isTablet } from "react-device-detect";

import { ReactComponent as Discord } from "../../assets/icon-discord.svg";
import { ReactComponent as Facebook } from "../../assets/icon-facebook-fill.svg";
import { ReactComponent as Twitter } from "../../assets/icon-twitter-fill.svg";
import { ReactComponent as Instagram } from "../../assets/icon-instagram-fill.svg";

import { ReactComponent as Crown } from "../../assets/crown.svg";

import { ReactComponent as Pellar } from "../../assets/pellar.svg";
import { ReactComponent as RIW } from "../../assets/riw.svg";
import { ReactComponent as VisualTonic } from "../../assets/visual-tonic.svg";

import "./index.scss";
import { debounce } from "lodash";

export const Footer = (props) => {
  const [footerColour, setFooterColour] = useState("black");
  const {
    refsRoamap,
    refsAbout,
    refsFaqs,
    refsTop,
    refsMint,
    refsBinJuice,
    refsVideo,
    refsTakeover,
    headerVisibility
  } = useSelector((state) => state.home);
  const refsFooter = useRef(null);

  useEffect(() => {
    if (props.scrollPosition) {
      const scrollY = props.scrollPosition;
      // top
      const topStart = refsTop.current.offsetTop;
      const topEnd = topStart + refsTop.current.getBoundingClientRect().height;

      // takeover
      // const takeoverStart = refsTakeover.current.offsetTop;
      // const takeoverEnd = takeoverStart + refsTakeover.current.getBoundingClientRect().height;

      // mint boundaries
      const mintStart = refsMint.current.offsetTop;
      const mintEnd =
        mintStart + refsMint.current.getBoundingClientRect().height;

      // video boundaries
      const videoStart = refsVideo.current.offsetTop;
      const videoEnd =
        videoStart + refsVideo.current.getBoundingClientRect().height;

      // bin juice
      const binJuiceStart = refsBinJuice.current.offsetTop;
      const binJuiceEnd = 
        binJuiceStart + refsBinJuice.current.getBoundingClientRect().height;

      // roadmap
      const roadmapStart = refsRoamap.current.offsetTop;
      const roadmapEnd =
        roadmapStart + refsRoamap.current.getBoundingClientRect().height;

      // faq
      const faqStart = refsFaqs.current.offsetTop;
      const faqEnd = faqStart + refsFaqs.current.getBoundingClientRect().height;

      // artist
      const artistStart = refsAbout.current.offsetTop;
      const artistEnd =
        artistStart + refsAbout.current.getBoundingClientRect().height;

      // top
      if (
        ((scrollY >= topStart &&
        scrollY <= topEnd) || 
        (scrollY >= binJuiceStart 
        && scrollY <= binJuiceEnd)) &&
        footerColour !== "black"
      ) {
        setFooterColour("black");
      }

      // mint
      if (
        ((scrollY >= mintStart && scrollY <= mintEnd) ||
          (scrollY >= faqStart && scrollY <= faqEnd)) &&
        footerColour !== "opaque"
      ) {
        setFooterColour("opaque");
      }

      // video onwards its all white
      if (
        ((scrollY >= roadmapStart && scrollY <= roadmapEnd) ||
          (scrollY >= videoStart && scrollY <= videoEnd) ||
          (scrollY >= artistStart && scrollY <= artistEnd)) &&
        footerColour !== "white"
      ) {
        setFooterColour("white");
      }
    }

    if (!isMobile && !isTablet) {
      refsFooter.current.classList.remove("show");
      handleEndScroll();
    }
  }, [props.scrollPosition]);

  useEffect(() => {
    if (!isMobile && !isTablet) {
      refsFooter.current.classList.add("show");
    }
  }, []);

  const scrollToRef = (ref) => window.scrollTo(0, ref.current.offsetTop);

  const handleEndScroll = useMemo(
    () => debounce(() => refsFooter.current.classList.add("show"), 700),
    []
  );

  return (
    <div className={`footer ${footerColour} ${!headerVisibility ? 'hidden' : 'show'}`} ref={refsFooter}>
      <div className={"wrapper" + ` ${footerColour}`}>
        <div className="container">
          <div className="wrap-list">
            <ul className="icons-container left">
              <li>
                <Link to={{
                  pathname: "https://facebook.com/scottmarshart"
                }} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className={"fa-brands fa-facebook-f" + ` ${footerColour}`}
                  />
                </Link>
              </li>
              <li>
                <Link
                  to={{
                    pathname: "https://instagram.com/scottie.marsh/"
                  }}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i
                    className={"fa-brands fa-instagram" + ` ${footerColour}`}
                  />
                </Link>
              </li>
              <li>
                <Link to={{
                  pathname: "https://twitter.com/scottie_marsh"
                }} 
                target="_blank">
                  <i className={"fa-brands fa-twitter" + ` ${footerColour}`} />
                </Link>
              </li>
              <li>
                <Link 
                  to={{
                    pathname: "https://discord.gg/bin-kingz"
                  }} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <i className={"fa-brands fa-discord" + ` ${footerColour}`} />
                </Link>
              </li>
            </ul>
            <div className="scott-icon" onClick={() => scrollToRef(refsTop)}>
              <Crown className={footerColour} />
            </div>
            <ul className="icons-container right">
              <li>
                <Link 
                  to={{
                    pathname: "https://www.runitwild.io"
                  }} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <RIW className={footerColour} />
                </Link>
              </li>
              <li>
                <Link 
                  to={{
                    pathname: "https://www.pellar.io"
                  }} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Pellar className={footerColour} />
                </Link>
              </li>
              <li>
                <Link 
                  to={{
                    pathname: "https://www.visualtonic.com.au/"
                  }} 
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <VisualTonic className={footerColour} />
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
