import {
  SET_REF_ROADMAP,
  SET_REF_ABOUT,
  SET_REF_FAQ,
  SET_REF_TOP,
  SET_REF_MINT,
  SET_REF_VIDEO,
  SET_WEB3,
  SET_ACCOUNT,
  SET_REF_TAKEOVER,
  SET_HEADER_VISIBILITY,
  SET_REF_BIN_JUICE
} from "../constants/home.action.types";

// sections
export const setRefTop = (payload) => {
  return {
    type: SET_REF_TOP,
    payload,
  }
}

export const setRefTakeover = (payload) => {
  return {
    type: SET_REF_TAKEOVER,
    payload,
  }
}

export const setRefMint = (payload) => {
  return {
    type: SET_REF_MINT,
    payload,
  }
}
export const setRefRoadmap = (payload) => {
  return {
    type: SET_REF_ROADMAP,
    payload,
  };
};

export const setRefFaq = (payload) => {
  return {
    type: SET_REF_FAQ,
    payload,
  };
};

export const setRefAbout = (payload) => {
  return {
    type: SET_REF_ABOUT,
    payload,
  };
};

export const setRefsVideo = (payload) => {
  return {
    type: SET_REF_VIDEO,
    payload,
  }
}

export const setRefBinJuice = (payload) => {
  return {
    type: SET_REF_BIN_JUICE,
    payload,
  }
}



export const setWeb3 = (payload) => {
  return {
    type: SET_WEB3,
    payload,
  };
};

export const setAccount = (payload) => {
  return {
    type: SET_ACCOUNT,
    payload,
  };
};

export const setHeaderVisibility = (payload) => {
  return {
    type: SET_HEADER_VISIBILITY,
    payload,
  }
}
