import React, { useEffect, useState } from "react";
import ReactGA from "react-ga";

import { Header } from "../header/header.jsx";
import { Footer } from "../footer/footer.jsx";
import { SectionTop } from "./sectionTop";
import { SectionMint } from "./sectionMint";
import { SectionVideo } from "./sectionVideo";
import { SectionFaq } from "./sectionFaq";
import { SectionAbout } from "./sectionAbout";
import { SectionRoadmap } from "./sectionRoadmap";
import { SectionBinJuice } from './sectionBinJuice';
import { Loading } from "../loading"
import { SectionVideoTakeOver } from "./sectionVideoTakeOver/index.jsx";
import { SectionRoadmapMobile } from "./sectionRoadmapMobile/index.jsx";

import Web3 from 'web3'
import { useDispatch, useSelector } from 'react-redux'
import { setAccount, setWeb3 } from '../../redux/actions/home.actions.js'
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "./index.scss";


ReactGA.initialize("UA-173020007-1");
ReactGA.pageview(window.location.pathname + window.location.search);

export const Home = (props) => {
  // dispatch
  const dispatch = useDispatch();
  const { ethereum } = window;
  const homeState = useSelector((state) => state.home);
  const account = homeState && homeState.account ? homeState.account : null;

  const [isMainnet, setIsMainnet] = useState(false)
  const [connecting, setConnecting] = useState(false)

  // METAMASK STUFF
  useEffect(() => {
    // init();
    accountsChanged();
    networkChanged()
  }, []);

  const handleConnectWallet = async () => {
    if (!account) {
      setConnecting(true)
      await loadWeb3(true);
    }
  };

  const networkChanged = () => {
    if (ethereum) {
      ethereum.on("networkChanged", (chainId) => {
        if (!chainId) {
          setIsMainnet(false)
          return toast.error('Network error, please check your metamask')
        }

        if (Number(chainId) !== 1) { // CHANGE TO MAINNET
          setIsMainnet(false)
          return toast.error('Please connect to the ethereum mainnet')
        }

        setIsMainnet(true)
        return toast.success('Connected to ethereum mainnet.')
      })
    }
  }

  const accountsChanged = () => {
    if (ethereum) {
      ethereum.on("accountsChanged", (accounts) => {
        if (accounts.length) {
          dispatch(setAccount(accounts[0]));
        } else {
          dispatch(setAccount(null));
        }
      });
    }
  };

  const init = async () => {
    await loadWeb3(true);
  };

  const loadWeb3 = async (isFirstLoad) => {
    try {
      let web3;

      if (ethereum) {
        web3 = new Web3(ethereum);
        // Ask User permission to connect to Metamask
        if (!isFirstLoad) {
          try {
            await window.ethereum.enable();
            setConnecting(false)
          } catch (err) {
            setConnecting(false)
            toast.error("Transaction rejected by user:", (err.message || ''));
          }
        } else {
          const chainId = await window.ethereum.request({
            method: "eth_chainId",
          });

          if (chainId !== "0x1") { // CHANGE TO MAINNET
            setIsMainnet(false)
            setConnecting(false)
            toast.error("Please connect to Ethereum Main Network");
          } else {
            ethereum
              .request({
                method: "eth_requestAccounts",
              })
              .then((accounts) => {
                if (accounts.length) {
                  dispatch(setAccount(accounts[0]));
                  setIsMainnet(true)
                  toast.success('Account connected')
                  setConnecting(false)
                }
              })
              .catch((err) => {
                const errorMsg = JSON.stringify(err);
                setConnecting(false)
                console.log(err);
              });
          }
        }
      } else if (window.web3) {
        web3 = new Web3(window.web3.currentProvider);
        setConnecting(false)
      } else {
        toast.error(
          "Non-Ethereum browser detected. Please install MetaMask plugin"
        );
        setConnecting(false)
        return;
      }

      dispatch(setWeb3(web3));
    } catch (err) {
      console.log("Loadweb3 err =>", err);
      setConnecting(false)
    }
  };




  // SCROLL
  // globalScrollPosition
  const [scrollPosition, setScrollPosition] = useState(null);
  useEffect(() => {
    window.addEventListener("scroll", () => setScrollPosition(window.scrollY));
    return () => {
      window.removeEventListener("scroll", () =>
        setScrollPosition(window.scrollY)
      );
    };
  }, [scrollPosition, setScrollPosition]);

  return (
    <>
      <Loading />
      <Header scrollPosition={scrollPosition} />
      <div className="home overflow-x-hidden">
        <div className="wrapper">
          <SectionTop scrollPosition={scrollPosition} />
          <SectionVideoTakeOver />
          <SectionMint 
            connectWallet={() => handleConnectWallet()}
            connecting={connecting}
            isMainnet={isMainnet}
          />
          <SectionVideo scrollPosition={scrollPosition} />
          <SectionBinJuice />
          <SectionRoadmap />
          <SectionRoadmapMobile />
          <SectionFaq scrollPosition={scrollPosition} />
          <SectionAbout />
        </div>
      </div>
      <Footer scrollPosition={scrollPosition} />

      <ToastContainer
        className='toast-container-bk'
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        theme="light"
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        style={{
          color: 'black'
        }}
      />
    </>
  );
};
