import React, { useState } from "react";

export const FaqItem = ({ question, answer, idx }) => {
  const [active, setActive] = useState(false);

  return (
    <div className="faq-item" key={idx}>
      <p
        className="faq-question"
        title={question}
        onClick={() => setActive(!active)}
      >
        {question}
      </p>
      <p className={`faq-answer ${active ? "active" : ""}`}>{answer}</p>
    </div>
  );
};
